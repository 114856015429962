import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch del menu dall'API REST di WordPress
    fetch("https://www.techjournal.it/wp-json/custom/v1/menus/main-menu")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Errore nel recupero del menu");
        }
        return response.json();
      })
      .then((data) => {
        setMenuItems(data); // Salva i dati del menu
        setLoading(false);
      })
      .catch((error) => {
        console.error("Errore:", error);
        setLoading(false);
      });
  }, []);

  // Funzione ricorsiva per renderizzare i sottomenu
  const renderMenu = (items) => {
    return (
      <ul className="menu">
        {items.map((item) => (
          <li key={item.id} className={item.children && item.children.length > 0 ? "has-children" : ""}>
            <Link to={item.url.replace("https://www.techjournal.it", "")}>
              {item.title}
            </Link>
            {item.children && item.children.length > 0 && (
              <ul className="submenu">
                {renderMenu(item.children)} {/* Chiamata ricorsiva per sottomenu */}
              </ul>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <header>
      <img
        src="https://static.techjournal.it/2024/01/logo-techjournal-250.png"
        width="250"
        height="50"
        alt="TechJournal"
      />
      <nav>
        {loading ? (
          <p>Caricamento menu...</p>
        ) : (
          renderMenu(menuItems) // Renderizza il menu usando la funzione ricorsiva
        )}
      </nav>
    </header>
  );
};

export default Header;