import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./PostList.css";

const PostList = ({ posts }) => {
  const [media, setMedia] = useState({});
  const [categories, setCategories] = useState({});

  const getFeaturedImage = (featuredMediaId) => {
    if (!featuredMediaId) return null;

    if (media[featuredMediaId]) {
      return media[featuredMediaId].source_url;
    }

    axios
      .get(`https://www.techjournal.it/wp-json/wp/v2/media/${featuredMediaId}`)
      .then((response) => {
        setMedia((prevMedia) => ({
          ...prevMedia,
          [featuredMediaId]: response.data,
        }));
      })
      .catch((error) => console.error("Errore nel recupero dell'immagine:", error));
  };

  const getCategoryNames = (categoryIds) => {
    if (!categoryIds || categoryIds.length === 0) return;

    categoryIds.forEach((id) => {
      if (categories[id]) return;

      axios
        .get(`https://www.techjournal.it/wp-json/wp/v2/categories/${id}`)
        .then((response) => {
          setCategories((prevCategories) => ({
            ...prevCategories,
            [id]: response.data.name,
          }));
        })
        .catch((error) =>
          console.error("Errore nel recupero della categoria:", error)
        );
    });
  };

  useEffect(() => {
    posts.forEach((post) => {
      if (post.featured_media) {
        getFeaturedImage(post.featured_media);
      }
      if (post.categories) {
        getCategoryNames(post.categories);
      }
    });
  }, [posts]);

  if (!posts || posts.length === 0) {
    return <p>Nessun articolo trovato.</p>;
  }

  return (
    <div className="post-list">
      {/* Slider principale con i primi 5 articoli */}
      <div className="highlighted-posts">
        {posts.slice(0, 5).map((post, index) => (
          <div
            key={post.id}
            className={`highlighted-post ${
              index === 0 ? "highlighted-main" : "highlighted-secondary"
            }`}
          >
            {post.featured_media && media[post.featured_media] && (
              <img
                src={media[post.featured_media].source_url}
                alt={post.title.rendered}
                className="highlighted-image"
              />
            )}
            <div className="highlighted-content">
              <p className="highlighted-category">
                {post.categories && post.categories.length > 0
                  ? categories[post.categories[0]] || "Categoria"
                  : "Categoria non disponibile"}
              </p>
              <h2 className="highlighted-title">
                <Link to={`/post/${post.slug}`} dangerouslySetInnerHTML={{ __html: post.title.rendered }}></Link>
              </h2>
            </div>
          </div>
        ))}
      </div>

      {/* Lista di post standard */}
      <div className="page">
        <div className="standard-posts">
            {posts.slice(5).map((post) => (
            <div key={post.id} className="post-card">
                <div className="post-image-container">
                {post.featured_media && media[post.featured_media] && (
                    <img
                    src={media[post.featured_media].source_url}
                    alt={post.title.rendered}
                    className="post-image"
                    />
                )}
                </div>
                <div className="post-content">
                <p className="post-category">
                    {post.categories && post.categories.length > 0
                    ? categories[post.categories[0]] || "Categoria"
                    : "Categoria non disponibile"}
                </p>
                <h2 className="post-title">
                    <Link to={`/post/${post.slug}`} dangerouslySetInnerHTML={{ __html: post.title.rendered }}></Link>
                </h2>
                <p className="post-date">
                    {new Date(post.date).toLocaleDateString("it-IT", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    })}
                </p>
                </div>
            </div>
            ))}
        </div>

        {/* Offerte/Widget */}
        <div className="widget-container">
            <div className="widget">
            <h3>Offerte</h3>
            <p>
                iPhone 15 ed iPhone 15 Pro disponibili per l'acquisto su Amazon
            </p>
            </div>
        </div>
      </div>  
    </div>
  );
};

export default PostList;