import React, { useEffect, useState } from "react";
import axios from "axios";
import PostList from "../components/PostList";

import "./HomePage.css"; // Stile per il layout

const HomePage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://www.techjournal.it/wp-json/wp/v2/posts")
      .then((response) => {
        setPosts(response.data); // Aggiorna lo stato con i dati ricevuti
        setLoading(false); // Cambia lo stato di loading a false
      })
      .catch((error) => {
        console.error("Errore nel fetch dei post:", error);
        setLoading(false); // Assicurati che il loading venga interrotto anche in caso di errore
      });
  }, []); // Il useEffect si esegue solo al primo montaggio del componente

  return (
    <div className="homepage">
      
      <div className="homepage-container">
      
        {/* Colonna principale */}
        <div className="homepage-main">
          {loading ? (
            <p>Caricamento articoli...</p> // Mostra il messaggio di caricamento
          ) : (
            <PostList posts={posts} /> // Quando i post sono caricati, mostra la lista
          )}
        </div>
        
      </div>
    </div>
  );
};

export default HomePage;