import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./SinglePost.css";

const SinglePost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [category, setCategory] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);

  // Funzione per recuperare l'immagine in evidenza
  const fetchFeaturedImage = (featuredMediaId) => {
    if (!featuredMediaId) return;

    axios
      .get(`https://www.techjournal.it/wp-json/wp/v2/media/${featuredMediaId}`)
      .then((response) => {
        setFeaturedImage(response.data.source_url);
      })
      .catch((error) =>
        console.error("Errore nel recupero dell'immagine in evidenza:", error)
      );
  };

  useEffect(() => {
    // Fetch del post
    axios
      .get(`https://www.techjournal.it/wp-json/wp/v2/posts?slug=${slug}`)
      .then((response) => {
        const postData = response.data[0];
        setPost(postData);

        // Fetch immagine in evidenza
        if (postData.featured_media) {
          fetchFeaturedImage(postData.featured_media);
        }

        // Fetch della categoria
        if (postData.categories && postData.categories.length > 0) {
          axios
            .get(
              `https://www.techjournal.it/wp-json/wp/v2/categories/${postData.categories[0]}`
            )
            .then((catResponse) => setCategory(catResponse.data))
            .catch((error) =>
              console.error("Errore nel fetch della categoria:", error)
            );
        }
      })
      .catch((error) => console.error("Errore nel fetch del post:", error));
  }, [slug]);

  if (!post) return <p>Caricamento...</p>;

  return (
    <div className="single-post">
      <div className="single-post-container">
        {/* Colonna principale */}
        <div className="single-post-main">
          {featuredImage && (
            <img
              src={featuredImage}
              alt={post.title.rendered}
              className="single-post-image"
            />
          )}
          <h1 className="single-post-title" dangerouslySetInnerHTML={{__html: post.title.rendered}} />
          {category && <p className="single-post-category">{category.name}</p>}
          <div
            className="single-post-content"
            dangerouslySetInnerHTML={{ __html: post.content.rendered }}
          />
        </div>
        {/* Colonna laterale per i widget */}
        <div className="single-post-sidebar">
          <h2>Widget</h2>
          <ul>
            <li>Widget 1</li>
            <li>Widget 2</li>
            <li>Widget 3</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;