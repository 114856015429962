import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PostList from "../components/PostList";

const CategoryPage = () => {
  const { categorySlug } = useParams(); // Prende lo slug della categoria dall'URL
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryId, setCategoryId] = useState(null);

  useEffect(() => {
    // Passo 1: Recupera l'ID della categoria utilizzando lo slug
    axios
      .get(`https://www.techjournal.it/wp-json/wp/v2/categories?slug=${categorySlug}`)
      .then((response) => {
        if (response.data.length > 0) {
          setCategoryId(response.data[0].id); // Salva l'ID della categoria
        }
      })
      .catch((error) => {
        console.error("Errore nel recupero dell'ID della categoria:", error);
      });
  }, [categorySlug]);

  useEffect(() => {
    // Passo 2: Recupera i post della categoria solo se l'ID è stato trovato
    if (categoryId) {
      axios
        .get(
          `https://www.techjournal.it/wp-json/wp/v2/posts?categories=${categoryId}`
        )
        .then((response) => {
          setPosts(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Errore nel fetch degli articoli per categoria:", error);
          setLoading(false);
        });
    }
  }, [categoryId]);

  return (
    <div>
      <h1>Articoli della categoria: {categorySlug}</h1>
      {loading ? (
        <p>Caricamento articoli...</p> // Mostra il messaggio di caricamento
      ) : (
        <PostList posts={posts} /> // Quando i post sono caricati, mostra la lista
      )}
    </div>
  );
};

export default CategoryPage;